// src/App.js  
import React from 'react';
import InteractiveLine from './InteractiveLine';
import './App.css';

function App() {
  const lineLength = 10; // Define the length of the line in cm  

  return (
    <div className="App">
      <InteractiveLine length={lineLength} x={5} y={5} />
    </div>
  );
}

export default App;