// src/InteractiveLine.js  
import React, { useState, useEffect } from 'react';
import { cmToPixels } from './utils';

const InteractiveLine = ({ length, x, y }) => {
    const [dotPosition, setDotPosition] = useState(null);
    const [distance, setDistance] = useState(0);
    const [lineStyle, setLineStyle] = useState({});

    useEffect(() => {
        const lengthInPixels = cmToPixels(length);
        setLineStyle({
            position: 'absolute',
            width: `${lengthInPixels}px`,
            height: '2px',
            backgroundColor: 'black',
            cursor: 'pointer',
            top: `${y}cm`,
            left: `${x}cm`
        });
    }, [length]);

    const handleLineClick = (e) => {
        const lineRect = e.target.getBoundingClientRect();
        const clickX = e.clientX - lineRect.left; // Get the click position relative to the line  
        const cmPerPixel = length / lineRect.width; // Calculate the cm per pixel ratio  

        const clickPositionCm = clickX * cmPerPixel; // Calculate the click position in cm  
        setDotPosition({ x: clickX });
        setDistance(clickPositionCm.toFixed(2)); // Round to two decimal places  
    };

    return (
        <div style={lineStyle} onClick={handleLineClick}>
            {dotPosition && (
                <div
                    style={{
                        position: 'absolute',
                        left: `${dotPosition.x}px`,
                        top: '-4px',
                        width: '10px',
                        height: '10px',
                        backgroundColor: 'red',
                        borderRadius: '50%'
                    }}
                ></div>
            )}
            <div style={{ marginTop: '20px', fontSize: '16px' }}>
                {dotPosition && `Dot is placed ${distance} cm from the start of the line.`}
            </div>
        </div>
    );
};

export default InteractiveLine;